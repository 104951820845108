<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="$router.currentRoute.params.id === undefined"
    >
      <h4 class="alert-heading">Error fetching summary data</h4>
      <div class="alert-body">
        <b-link class="alert-link">
          Halaman ini tidak bisa di refresh atau dibuka melalui direct link,
        </b-link>
        halaman bersifat one time access atau hanya dapat di akses melalui
        button pada kolom <strong>Details</strong> summary.
      </div>
    </b-alert>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo">
                    TBBM - Pelayanan Kapal
                  </h3>
                </div>
                <p class="card-text mb-25">PT. Sarana Abadi Lestari</p>
                <p class="card-text mb-25">Kec. Palaran, Kota Samarinda</p>
                <p class="card-text mb-25">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p>
                <p class="card-text mb-0">Kalimantan Timur 75261</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Summary
                  <span class="invoice-number"
                    >#{{ form.form_chain_tbbm_id }}</span
                  >
                </h4>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Application') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.created_at) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AnchorIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Departure') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.sandar_end_time) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="BookOpenIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Activity') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.schedule.kegiatan }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CpuIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Jenis Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.schedule.jenis_kapal }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Form Status') }}
                      </span>
                    </th>
                    <td>
                      <b-badge
                        :variant="formStatusVariant(form.schedule.form_status)"
                      >
                        {{ form.schedule.form_status }}
                      </b-badge>
                      <!-- <span
                        v-if="
                          form.schedule.jenis_kapal === 'Barge' &&
                          form.schedule.no_rpkro &&
                          form.schedule.no_rpkro_tb &&
                          form.schedule.form_status !== 'KAPAL BERANGKAT'
                        "
                        v-text="` DIIJINKAN SANDAR`"
                      />
                      <span
                        v-else-if="
                          form.schedule.jenis_kapal !== 'Barge' &&
                          form.schedule.no_rpkro &&
                          form.schedule.form_status !== 'KAPAL BERANGKAT'
                        "
                        v-text="` DIIJINKAN SANDAR`"
                      />
                      <span
                        v-else-if="
                          form.schedule.form_status === 'KAPAL BERANGKAT' ||
                          form.schedule.form_status === 'KAPAL SANDAR' ||
                          form.schedule.form_status === 'KEGIATAN DIBATALKAN'
                        "
                        v-text="` `"
                      /> -->
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Pemilik') }}
                      </span>
                    </th>
                    <td>
                      {{ form.schedule.pemilik }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>
          <!-- KAPAL BARGE -->
          <b-card-body
            v-if="form.schedule.jenis_kapal === 'Barge'"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Main Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                  </div>
                </div>

                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.dm_river_boat.boat_name)"
                    >
                      {{ form.dm_river_boat.boat_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      class="pb-50 cursor-pointer"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_pkk)"
                    >
                      {{ form.schedule.no_pkk }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.gt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.dm_river_boat.loa }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.captain_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.captain_telp }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_shipping_agent.shipping_name }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Tug Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Tug Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                  </div>
                </div>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.tug_boat.name_tug_boat)"
                    >
                      {{ form.tug_boat.name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      class="pb-50 cursor-pointer"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.tug_boat.no_pkk_tug_boat)"
                    >
                      {{ form.tug_boat.no_pkk_tug_boat }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.gt_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.tug_boat.loa_tug_boat }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_telp_tug_boat }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.dm_shipping_agent.shipping_name }}
                    </td>
                  </tr> -->
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- KAPAL NON BARGE -->
          <b-card-body class="invoice-padding pb-0" v-else>
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="AnchorIcon" />
                  KAPAL
                  <feather-icon icon="AnchorIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-6 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.dm_river_boat.boat_name)"
                    >
                      {{ form.dm_river_boat.boat_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      v-b-tooltip.hover
                      title="click to copy"
                      class="pb-50"
                      @click="copyText(form.schedule.no_pkk)"
                    >
                      {{ form.schedule.no_pkk }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.gt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.dm_river_boat.loa }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.captain_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat.captain_telp }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_shipping_agent.shipping_name }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- SCHEDULE BARGE -->
          <b-card-body
            v-if="form.schedule.jenis_kapal === 'Barge'"
            class="invoice-padding pb-0"
          >
            <b-card-body class="invoice-padding pb-0">
              <div class="divider my-2">
                <div class="divider-text">
                  <b-badge :variant="'info'">
                    <feather-icon icon="CalendarIcon" />
                    Schedule
                    <feather-icon icon="CalendarIcon" />
                  </b-badge>
                </div>
              </div>
            </b-card-body>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-8 p-0 mt-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <b-badge>
                    Pemilik (1) {{ this.pemilik_1 }}
                  </b-badge>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Volume Muatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatNumber(form.schedule.volume_muatan) || '-' }} Liter
                    </td>
                  </tr>
                  <b-badge v-if="this.pemilik_2">
                    Pemilik (2) {{ this.pemilik_2 }}
                  </b-badge>
                  <tr v-if="this.pemilik_2">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Volume Muatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatNumber(form.schedule.volume_muatan_tb) || '-' }} Liter
                    </td>
                  </tr>
                  <tr v-if="form.schedule.total_bunker">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Total Bunker') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatNumber(form.schedule.total_bunker) || '-' }} Liter
                    </td>
                  </tr>
                  <tr v-if="form.schedule.total_bunker">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor Ijin Bunker') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      title="click to copy"
                      @click="copyText(form.schedule.no_ijin_bunker)"
                    >
                      {{ form.schedule.no_ijin_bunker || '-' }}
                    </td>
                  </tr>
                  <hr/>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Asal Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_asal_kapal.asal }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Rencana Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.rencana_sandar) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Main Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                    <h5>{{ form.dm_river_boat.boat_name }}</h5>
                  </div>
                </div>

                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor Pindah') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.no_pindah || 'opsional' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor RKBM') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_rkbm)"
                    >
                      {{ form.schedule.no_rkbm || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PBMBB') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_pbmbb)"
                    >
                      {{ form.schedule.no_pbmbb || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Tug Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Tug Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                    <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                  </div>
                </div>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor Pindah') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.no_pindah_tb || 'opsional' }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Nomor RKBM') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.no_rkbm_tb || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Nomor PBMBB') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.no_pbmbb_tb || '-' }}
                    </td>
                  </tr> -->
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- SCHEDULE else -->
          <b-card-body class="invoice-padding pb-0" v-else>
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="CalendarIcon" />
                  Schedule
                  <feather-icon icon="CalendarIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-10 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor Pindah') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.no_pindah || 'opsional' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor RKBM') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_rkbm)"
                    >
                      {{ form.schedule.no_rkbm || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PBMBB') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_pbmbb)"
                    >
                      {{ form.schedule.no_pbmbb || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Volume Muatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.volume_muatan || '-' }} Liter
                    </td>
                  </tr>
                  <tr v-if="form.schedule.total_bunker">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Total Bunker') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.total_bunker || '-' }} Liter
                    </td>
                  </tr>
                  <tr v-if="form.schedule.total_bunker">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor Ijin Bunker') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      title="click to copy"
                      @click="copyText(form.schedule.no_ijin_bunker)"
                    >
                      {{ form.schedule.no_ijin_bunker || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Asal Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_asal_kapal.asal }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Rencana Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.rencana_sandar) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- Attachments -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="HardDriveIcon" />
                  Document
                  <feather-icon icon="HardDriveIcon" />
                </b-badge>
                <br />
                <strong>[ click name file, to preview.]</strong>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <app-timeline-item
                v-for="(item, index) in isHaveFile"
                :key="index"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                ></div>
                <p>
                  <b-img
                    :src="icons(item)"
                    height="auto"
                    width="20"
                    class="mr-1"
                  />
                  <span class="align-bottom">
                    <a
                      :href="item.download_link"
                      target="_blank"
                      style="color: grey"
                      rel="noopener noreferrer"
                      >{{ item.original_name }}</a
                    >
                  </span>
                </p>
                <small class="text-muted">Size {{ item.size }} </small>
              </app-timeline-item>
            </div>
          </b-card-body>
          <!-- Approvements -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="CheckSquareIcon" />
                  Approvement
                  <feather-icon icon="CheckSquareIcon" />
                </b-badge>
              </div>
            </div>
            <br />
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-12 p-0 mt-0">
                <div class="p-0">
                  <table class="mt-2 mt-xl-0 w-100">
                    <!-- <tr>
                      <th class="pb-50">
                        <span class="font-weight-bold">
                          {{ $t('Nomor DO') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_do_bl || '-' }}
                      </td>
                    </tr> -->
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Approve BY') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.approve_by || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Approve AT') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ formatDateTime(form.schedule.ijin_lengkap_time) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Jetty Man -->
          <b-card-body class="invoice-padding pb-0">
            <br />
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="MapIcon" />
                  Jetty Man
                  <feather-icon icon="MapIcon" />
                </b-badge>
                <br />
                [ Tujuan Kapal : {{ form.schedule.tujuan_kapal }} ]
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- posisi main boat -->
              <div class="col col-md-4 p-0 mt-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.position_jetty || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Baris Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.position_baris_jetty || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- posisi tug boat boat -->
              <div
                class="col col-md-4 p-0 mt-0"
                v-if="form.schedule.jenis_kapal === 'Barge'"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.position_jetty_tb || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Baris Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.position_baris_jetty_tb || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- <hr /> -->
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <!-- <b-badge :variant="'info'">RKA 1</b-badge> -->
                    <h5>{{ form.dm_river_boat.boat_name }}</h5>
                    <span
                      style="
                        @media (min-width: 390px) and (max-width: 768px) {
                          display: block;
                          word-wrap: break-word;
                          width: 50px;
                          white-space: normal;
                        }
                      "
                      >{{ this.form_realisasi.rka1 }}</span
                    >
                    <br />
                    <strong><span>Sandar → Berangkat</span></strong>
                  </div>
                </div>
                <table class="mt-2 mt-xl-0 w-100 p-0">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.sandar_start_time) || '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Berangkat') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.sandar_end_time) || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Tug Boat -->
              <div
                class="col col-md-6 p-0"
                v-if="form.schedule.jenis_kapal === 'Barge'"
              >
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                    <span
                      style="
                        @media (min-width: 390px) and (max-width: 768px) {
                          display: block;
                          word-wrap: break-word;
                          width: 50px;
                          white-space: normal;
                        }
                      "
                      >{{ this.form_realisasi.rka2 }}</span
                    >
                    <br />
                    <strong><span>Sandar → Berangkat</span></strong>
                  </div>
                </div>
                <!-- content -->
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.tug_boat.tug_sandar_start_time) ||
                        '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Berangkat') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.tug_boat.tug_sandar_end_time) || '-'
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>
          <!-- Admin SAL -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="UnlockIcon" />
                  Realisasi
                  <feather-icon icon="UnlockIcon" />
                </b-badge>
              </div>
            </div>
            <b-alert
                variant="info"
                :show="isNoRpkro"
              >
                <h4 class="alert-heading">Jenis [TBBM] LCT / KM / MT / SPOB / Sea Truck</h4>
                <div class="alert-body">

                   jika hanya berkegiatan
                   <b-link class="alert-link">
                    BUNKER
                  </b-link>
                  maka tidak diperlukan pengisian Nomor RPKRO & Nomor SPOG.
                </div>
              </b-alert>

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
            >
              <div class="col col-md-6 p-0">
                <!-- Main Boat -->
                <!-- <div class="divider my-2">
                  <div class="divider-text">
                    <h5>{{ form.dm_river_boat.boat_name }}</h5>
                    {{ form_realisasi.keg_p1 ?? '-' }}
                    <br />
                    <strong>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >Mulai Berkegiatan → Selesai Berkegiatan</span
                      >
                    </strong>
                  </div>
                </div> -->
                <div class="divider my-2" v-if="form_realisasi.bunker">
                  <div class="divider-text">
                    {{ form_realisasi.bunker }}
                    <br />
                    <strong>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >Mulai Berkegiatan Bunker → Selesai Berkegiatan Bunker</span
                      >
                    </strong>
                  </div>
                </div>
              </div>
              <div
                class="col col-md-6 p-0"
                v-if="form.schedule.jenis_kapal === 'Barge'"
              >
                <!-- Tug Boat -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                    <span
                      style="
                        @media (min-width: 390px) and (max-width: 768px) {
                          display: block;
                          word-wrap: break-word;
                          width: 50px;
                          white-space: normal;
                        }
                      "
                      >
                      <!-- {{ this.form_realisasi.keg_tb }} -->
                      -split-time-
                      </span
                    >
                    <br />
                    <strong>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >Mulai Berkegiatan → Selesai Berkegiatan</span
                      >
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <table class="mt-2 mt-xl-0 w-100 p-0">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor RPKRO') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_rpkro)"
                    >
                      {{ form.schedule.no_rpkro || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        Nomor PPK
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_ppk)"
                    >
                      {{ form.schedule.no_ppk || '-' }}  <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50"
                      v-b-tooltip.hover
                      title="Disetujui sandar / Waktu RPKRO Terbit"
                    >
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold align-middle">
                        {{ $t('Disetujui Sandar') }} <feather-icon icon="InfoIcon"/>
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.disetujui_time) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor SPOG') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_spog)"
                    >
                      {{ form.schedule.no_spog || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Mulai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_start_time) || '-'
                      }}
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Selesai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_end_time) || '-'
                      }}
                    </td>
                  </tr> -->
                  <tr v-if="form.schedule.kegiatan !== 'BUNKER'">
                    <div class="divider my-2">
                      <div class="divider-text">
                        <b-badge :variant="'info'">
                          <feather-icon icon="AnchorIcon" />
                          Pemilik (1) {{ this.pemilik_1 }} Waktu Berkegiatan
                          <feather-icon icon="AnchorIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </tr>
                  <tr v-if="form.schedule.kegiatan !== 'BUNKER'">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Mulai') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_start_time_p1) || '-'
                      }}
                    </td>
                  </tr>
                  <tr v-if="form.schedule.kegiatan !== 'BUNKER'">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Selesai') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_end_time_p1) || '-'
                      }}
                    </td>
                  </tr>
                  <tr v-if="form.schedule.kegiatan !== 'BUNKER'">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Total') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        form_realisasi.keg_p1
                      }}
                    </td>
                  </tr>

                    <b-badge v-if="this.pemilik_2">
                      Pemilik (2) {{ this.pemilik_2 }}
                    </b-badge>
                    <tr v-if="this.pemilik_2">
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Mulai') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{
                          formatDateTime(form.schedule.kegiatan_start_time_p2) || '-'
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.pemilik_2">
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Selesai') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{
                          formatDateTime(form.schedule.kegiatan_end_time_p2) || '-'
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.pemilik_2">
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Total') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{
                          form_realisasi.keg_p2
                        }}
                      </td>
                    </tr>

                </table>
              </div>
              <!-- Tug Boat -->

              <div
                class="col col-md-6 p-0"
                v-if="form.schedule.jenis_kapal === 'Barge'"
              >
                <table class="mt-2 mt-xl-0 w-100 p-0">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor RPKRO') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_rpkro_tb)"
                    >
                      {{ form.schedule.no_rpkro_tb || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        Nomor PPK
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_ppk_tb)"
                    >
                      {{ form.schedule.no_ppk_tb || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Disetujui Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.disetujui_time_tb) || '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor SPOG') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_spog_tb)"
                    >
                      {{ form.schedule.no_spog_tb || '-' }} <feather-icon icon="CopyIcon"/>
                    </td>
                  </tr>
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Mulai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_start_time_tb) ||
                        '-'
                      }}
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Selesai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.kegiatan_end_time_tb) ||
                        '-'
                      }}
                    </td>
                  </tr> -->
                  <tr>
                  <div class="divider my-2">
                    <div class="divider-text">
                      <b-badge :variant="'info'">
                        <feather-icon icon="AnchorIcon" />
                        [2] Waktu Berkegiatan
                        <feather-icon icon="AnchorIcon" />
                      </b-badge>
                    </div>
                  </div>
                </tr>
                  <b-badge>
  Pemilik (1) {{ this.pemilik_1 }}
</b-badge>
<tr>
  <th class="pb-50">
    <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
    <span class="font-weight-bold">
      {{ $t('Mulai') }}
    </span>
  </th>
  <td class="pb-50">
    {{
      formatDateTime(form.schedule.kegiatan_start_time_tb_p1) || '-'
    }}
  </td>
</tr>
<tr>
  <th class="pb-50">
    <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
    <span class="font-weight-bold">
      {{ $t('Selesai') }}
    </span>
  </th>
  <td class="pb-50">
    {{
      formatDateTime(form.schedule.kegiatan_end_time_tb_p1) || '-'
    }}
  </td>
</tr>
<tr>
  <th class="pb-50">
    <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
    <span class="font-weight-bold">
      {{ $t('Total') }}
    </span>
  </th>
  <td class="pb-50">
    {{
      form_realisasi.keg_tb_p1
    }}
  </td>
</tr>

  <b-badge v-if="this.pemilik_2">
    Pemilik (2) {{ this.pemilik_2 }}
  </b-badge>


  <tr v-if="this.pemilik_2">
    <th class="pb-50">
      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
      <span class="font-weight-bold">
        {{ $t('Mulai') }}
      </span>
    </th>
    <td class="pb-50">
      {{
        formatDateTime(form.schedule.kegiatan_start_time_tb_p2) || '-'
      }}
    </td>
  </tr>
  <tr v-if="this.pemilik_2">
    <th class="pb-50">
      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
      <span class="font-weight-bold">
        {{ $t('Selesai') }}
      </span>
    </th>
    <td class="pb-50">
      {{
        formatDateTime(form.schedule.kegiatan_end_time_tb_p2) || '-'
      }}
    </td>
  </tr>
  <tr v-if="this.pemilik_2">
    <th class="pb-50">
      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
      <span class="font-weight-bold">
        Total
      </span>
    </th>
    <td class="pb-50">
      {{
        form_realisasi.keg_tb_p2
      }}
    </td>
  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <b-card-body>
            <strong class="mr-2 mb-2 text-center">
              <span v-text="`Form Perencanaan Created BY ${form.created_by}`" />
            </strong>
          </b-card-body>
          <br />

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>
              It was a pleasure working with you and your team. We hope you will
              keep us in mind for future shipping. Thank You!
            </span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
        style="position: fixed; right: 0"
      >
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="true"
          >
            Send Email
          </b-button> -->

          <!-- Button: DOwnload
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button> -->

          <!-- Button: Edit -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{
              name: 'apps-invoice-edit',
              params: { id: $route.params.id }
            }"
          >
            Edit
          </b-button> -->

          <!-- Button: Add Payment -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="$router.currentRoute.params.previous_link"
          >
            <!-- @click="hasHistory() ? $router.go(-2) : $router.go(-1)" -->
            <feather-icon icon="HomeIcon" class="mr-75" />
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            :to="{
              name: 'dashboard-monitoring-rrv-tbbm-j3a-view',
              params: {
                id: form.form_chain_tbbm_id,
                api: 'form_chain_tbbm',
                previous_link: $router.currentRoute
              }
            }"
          >
            <feather-icon icon="PenToolIcon" class="mr-75" />
            Realisasikan
          </b-button>
        </b-card>
        <b-card v-if="showVerification() || checkIsNoRpkro(form) === false">
          <h3>Verification</h3>
          <ol>
            <!-- SAL -->
            <span v-if="$can('SAL', '') || $can('TBBM JETTY 3A', '')">
              <!-- show only for admin sal -->
              <b-badge
                :variant="'light-warning'"
                class="d-block"
                v-if="
                  !form.schedule.no_rpkro ||
                  !form.schedule.no_spog ||
                  !form.schedule.kegiatan_start_time_p1 ||
                  !form.schedule.kegiatan_start_time_p2
                "
              >
                {{ form.dm_river_boat.boat_name }}
              </b-badge>
              <li v-if="!form.schedule.no_rpkro || checkIsNoRpkro(form) === true">
                <span
                  v-html="
                    'Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)'
                  "
                ></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>
              <li v-if="!form.schedule.no_spog  || checkIsNoRpkro(form) === true">
                <span v-html="'Belum mengisi Nomor SPOG'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>
              <li v-if="form.schedule.kegiatan === 'BUNKER' && !form_realisasi.bunker">
                <span v-html="'Belum mengisi waktu kegiatan Bunker'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>
              <!-- <li v-if="form.schedule.kegiatan !== 'BUNKER' && !!form.schedule.kegiatan_start_time_p1 || !!form.schedule.kegiatan_start_time_p2"> -->
              <li v-if="form.schedule.kegiatan !== 'BUNKER' && !form.schedule.kegiatan_start_time_p1">
                <span v-html="'Belum mengisi waktu Realisasi Kegiatan'"></span>
                {{form.schedule.kegiatan_start_time_p1}}
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>

              <span v-if="form.schedule.jenis_kapal === 'Barge'">
                <!-- show only for tug boat -->
                <b-badge
                  :variant="'light-warning'"
                  class="d-block"
                  v-if="
                    !form.schedule.no_rpkro_tb ||
                    !form.schedule.no_spog_tb ||
                    !form.schedule.kegiatan_start_time_tb_p1 ||
                    !form.schedule.kegiatan_start_time_tb_p2
                  "
                >
                  {{ form.tug_boat.name_tug_boat }}
                </b-badge>
                <li v-if="!form.schedule.no_rpkro_tb">
                  <span
                    v-html="
                      'Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)'
                    "
                  ></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.no_spog_tb">
                  <span v-html="'Belum mengisi Nomor SPOG'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.kegiatan_start_time_tb_p1 || !form.schedule.kegiatan_start_time_tb_p2">
                  <span v-html="'Belum mengisi waktu kegiatan'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <!-- show only for tug boat -->
              </span>
              <!-- show only for admin sal -->
              <hr/>
              <b-alert
                variant="info"
                :show="isNoRpkro"
              >
                <h4 class="alert-heading">Jenis [TBBM] LCT / KM / MT / SPOB / Sea Truck</h4>
                <div class="alert-body">

                   jika hanya berkegiatan
                   <b-link class="alert-link">
                    BUNKER
                  </b-link>
                  maka tidak diperlukan pengisian Nomor RPKRO & Nomor SPOG.
                </div>
              </b-alert>
            </span>
          </ol>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    ToastificationContent
  },
  computed: {
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    checkIsNoRpkro(){ /* props.row parameter */
      return (form) => {
        const isBunker = form.kegiatan
        const isLct = form.jenis_kapal
        const no_do_bl = form.no_do_bl
        const hardcodeJenisKapal = 'LCT / KM / MT / SPOB / Sea Truck'
        const hardcodeKegiatan = 'BUNKER'

        if(isBunker === hardcodeKegiatan && isLct === hardcodeJenisKapal){
          return false
        }
        return false
      }
    },
    showVerification() {
      return () => {
        if (this.$can('SAL', '')) {
          const cond =
            !this.form.schedule.no_rpkro ||
            !this.form.schedule.no_spog ||
            !this.form.schedule.kegiatan_start_time_p1 ||
            !this.form.schedule.kegiatan_start_time_p2
          const cond1 =
            !this.form.schedule.no_rpkro_tb ||
            !this.form.schedule.no_spog_tb ||
            !this.form.schedule.kegiatan_start_time_tb_p1 ||
            !this.form.schedule.kegiatan_start_time_tb_p2

          if (this.form.schedule.jenis_kapal === 'Barge') {
            return cond || cond1
          } else {
            return cond
          }
        }
        if (this.$can('manage', 'special')) {
          return !this.form.schedule.no_do_bl
        }
        if (this.$can('TBBM JETTY 3A', '')) {
          const no_rpkro = this.form.schedule.no_rpkro
          const no_rpkro_tb = this.form.schedule.no_rpkro_tb
          const no_spog = this.form.schedule.no_spog
          if (no_rpkro || no_rpkro_tb) {
            if (!no_spog) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }
        return true
      }
    }
  },

  setup() {
    const paymentDetails = {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription:
          'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00'
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription:
          'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00'
      }
    ]

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceDescription,
      printInvoice,
      paymentDetails
    }
  },
  data() {
    return {
      isNoRpkro: false,
      yoTimeoutDebounce: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      invoiceData: {
        id: 1,
        issuedDate: '13 Dec 2019',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson'
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        balance: '$724',
        dueDate: '23 Apr 2019'
      },
      isHaveFile: null,
      form_realisasi: {
        rka1: null,
        rka2: null,
        keg: null,
        keg_tb: null,
        /* bunker specify */
        bunker: null,
        bunker_s: null,
        bunker_e: null,
      },
      form: {
        verification_hm: [],
        flag_verified: null,
        /* for form chain tbbm */
        form_chain_tbbm_id: null,

        form_tug_boat_id: null,
        form_tbbm_id: null,

        uri_chain_master: 'form_chain_tbbm',
        uri_chain_main:
          '' /* already override by form tbbm  in schedule payload */,
        uri_chain_second: 'form_tug_boat',
        /* for form chain hm */

        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,
        currentFieldHaveChildren: null,

        isSuccessTugBoat: false,
        isTugBoatOnly: false,
        // isAgenAndTrans: false,
        // showIsAgenAndTrans: false,
        // temp_transportir_id: '',

        group: '',
        pemilik: '',
        pemilik_1: '',
        pemilik_2: '',
        created_by: '',
        dm_river_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_river_boat/',
          isNew: true,
          boat_name: '',
          gt: null,
          loa: null,
          captain_name: '',
          captain_telp: '',
          // created_at: moment(),
          dm_river_boats: []
        },
        dm_shipping_agent: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_shipping_agent/',
          isNew: true,
          shipping_name: '',
          dm_shipping_agents: []
        },
        dm_asal_kapal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_asal_kapal/',
          isNew: true,
          asal: '',
          dm_asal_kapals: []
        },
        tug_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'tug_boat/',
          isNew: true,
          no_pkk_tug_boat: '',
          name_tug_boat: '',
          tug_sandar_start_time: null,
          tug_sandar_end_time: null,
          tug_boats: [],
          gt_tug_boat: null,
          loa_tug_boat: null,
          captain_name_tug_boat: '',
          captain_telp_tug_boat: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        schedule: {
          no_ijin_bunker: null,
          total_bunker: null,
          // api
          uri: 'form_tbbm/',
          uriAgent: 'form_tbbm/update/persetujuan/',
          uriStatus: 'form_tbbm/update/status/',
          status_kapal: '',
          // payload
          // kegiatan: '',
          jenis_kapal: null,
          tug_boat_id: null,
          tujuan_kapal: null,

          rencana_sandar: null,
          no_do_bl: '',
          no_pkk: '',
          pemilik: '',
          uuid: uuidv4()
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  methods: {
    moment,
    formatNumber(number) {
      if(number != null){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        return 0;
      }
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    hasHistory() {
      return window.history.length > 3
    },
    icons(item) {
      console.log('item.type', item.type)
      if (['image/png', 'png'].includes(item.type)) {
        return require('@/assets/images/icons/png.png')
      }
      if (['image/jpeg', 'jpeg', 'jpg'].includes(item.type)) {
        return require('@/assets/images/icons/jpg.png')
      }
      if (['application/pdf'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
      if (['xls'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    formatDateTime(value, format = 'D MMMM YYYY - HH:mm') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    async fetchData() {
      const uriAPI = 'form_chain_tbbm'
      // const dataID = 1
      const dataID = this.$router.currentRoute.params.id

      if (dataID !== undefined) {
        this.isTimerStart()

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            console.log('fetchData:', response.data[`${uriAPI}s`])
            this.isTimerDone()

            const origin = response.data[`${uriAPI}s`]
            let kegiatan_tambahan = origin.kegiatan_tambahan
            let pemilik_dua = origin.pemilik_dua
            this.pemilik_2 = pemilik_dua
            const {
              user,
              form_tbbm,
              form_tug_boat,
              file_storages,
              file_storage_bunkers,
              volume_muatan_tb,
            } = response.data[`${uriAPI}s`]

            console.log('fetchData:', this.form)
            this.form.form_chain_tbbm_id = origin.id || null
            this.form.form_tbbm_id = form_tbbm.id || null
            this.form.schedule.tujuan_kapal = form_tbbm.tujuan_kapal

            this.isHaveFile = []
            if (file_storages) {
              file_storages.forEach((item) => {
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  type: item.type,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }

            const {
              dm_river_boat,
              // dm_company_bongm,
              dm_shipping_agent,
              dm_asal_kapal
            } = form_tbbm

            if (form_tbbm.file_storages) {
              form_tbbm.file_storages.forEach((item) => {
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  type: item.type,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }
            // eslint-disable-next-line camelcase
            // this.form.group = group.name
            this.form.created_by = `${user.full_name} ( ${
              user.perusahaan || ''
            } ${!user.perusahaan ? '' : '-'} ${user.phone} )`

            this.form.schedule.pemilik = form_tbbm.pemilik
            this.pemilik_1 = form_tbbm.pemilik
            if (pemilik_dua) {
              this.form.schedule.pemilik = `${form_tbbm.pemilik}, ${pemilik_dua}`
            }
            this.form.schedule.approve_by = form_tbbm.approve_by

            // // payload association
            this.form.schedule.dm_river_boat_id = dm_river_boat.id
            // this.form.schedule.dm_company_bongm_id = dm_company_bongm.id
            this.form.schedule.dm_shipping_agent_id = dm_shipping_agent.id

            // // payload
            this.form.schedule.volume_muatan = form_tbbm.volume_muatan
            this.form.schedule.volume_muatan_tb = volume_muatan_tb

            this.form.schedule.jenis_kapal = form_tbbm.jenis_kapal
            this.form.schedule.rencana_sandar = form_tbbm.rencana_sandar
            this.form.schedule.no_pkk = form_tbbm.no_pkk
            this.form.schedule.no_do_bl = form_tbbm.no_do_bl
            this.form.schedule.kegiatan = form_tbbm.kegiatan
            if (kegiatan_tambahan) {
              this.form.schedule.kegiatan = `${form_tbbm.kegiatan}, ${kegiatan_tambahan}`
            }
            this.form.schedule.created_at = form_tbbm.created_at
            // this.form.schedule.keterangan = form_tbbm.keterangan

            this.form.schedule.no_rkbm = form_tbbm.no_rkbm
            this.form.schedule.no_pbmbb = form_tbbm.no_pbmbb
            this.form.schedule.no_pindah = form_tbbm.no_pindah

            this.form.schedule.no_rkbm_tb = form_tbbm.no_rkbm_tb
            this.form.schedule.no_pbmbb_tb = form_tbbm.no_pbmbb_tb
            // this.form.schedule.no_pindah_tb = form_tbbm.no_pindah_tb // already move to form tug boat only
            this.tempCheckPindah = form_tbbm.no_pindah
            this.form.schedule.ijin_lengkap_time = form_tbbm.ijin_lengkap_time

            this.form.schedule.no_rpkro = form_tbbm.no_rpkro
            this.form.schedule.no_ppk = form_tbbm.no_ppk
            this.tempCheckRPKRO = form_tbbm.no_rpkro
            this.form.schedule.disetujui_time = form_tbbm.disetujui_time

            this.form.schedule.no_spog = form_tbbm.no_spog

            this.form.schedule.position_jetty = form_tbbm.position_jetty
            this.tempCheckAlrSandarBrs = form_tbbm.position_jetty
            this.form.schedule.position_baris_jetty =
              form_tbbm.position_baris_jetty
            this.tempCheckAlrSandar = form_tbbm.position_baris_jetty

            this.form.schedule.sandar_start_time = form_tbbm.sandar_start_time
            console.log(
              'form_tbbm.sandar_start_time::',
              form_tbbm.sandar_start_time
            )
            console.log(
              'this.form.schedule.sandar_start_time::',
              this.form.schedule.sandar_start_time
            )
            this.form.schedule.sandar_end_time = form_tbbm.sandar_end_time
            this.form.schedule.kegiatan_batal_time =
              form_tbbm.kegiatan_batal_time

            let keg_s = form_tbbm.kegiatan_start_time
            let keg_e = form_tbbm.kegiatan_end_time
            let keg_s_p1 = form_tbbm.kegiatan_start_time_p1
            let keg_e_p1 = form_tbbm.kegiatan_end_time_p1
            let keg_s_p2 = form_tbbm.kegiatan_start_time_p2
            let keg_e_p2 = form_tbbm.kegiatan_end_time_p2

            this.form.schedule.kegiatan_start_time = keg_s
            this.form.schedule.kegiatan_end_time = keg_e
            this.form_realisasi.keg = this.durationAsString(
              moment(keg_s),
              moment(keg_e)
            )
            this.form.schedule.kegiatan_start_time_p1 = keg_s_p1
            this.form.schedule.kegiatan_end_time_p1 = keg_e_p1
            this.form_realisasi.keg_p1 = this.durationAsString(
              moment(keg_s_p1),
              moment(keg_e_p1)
            )
            this.form.schedule.kegiatan_start_time_p2 = keg_s_p2
            this.form.schedule.kegiatan_end_time_p2 = keg_e_p2
            this.form_realisasi.keg_p2 = this.durationAsString(
              moment(keg_s_p2),
              moment(keg_e_p2)
            )

            this.form.schedule.form_status = form_tbbm.form_status
            this.form.schedule.is_cancel = form_tbbm.is_cancel
            this.form.schedule.updated_at = form_tbbm.updated_at
            let nowRKA1 = moment(form_tbbm.sandar_start_time)
            let endRKA1 = moment(form_tbbm.sandar_end_time) // another date
            this.form_realisasi.rka1 = this.durationAsString(nowRKA1, endRKA1)

            // helper to not update kegiatan_batal_time
            this.form.schedule.set_kegiatan_batal_time = false

            if (dm_asal_kapal !== null) {
              console.log('dm_asal_kapal: exist', dm_asal_kapal)
              this.form.schedule.dm_asal_kapal_id = dm_asal_kapal.id
              this.form.dm_asal_kapal = {
                // eslint-disable-next-line camelcase
                ...dm_asal_kapal,
                updateID: dm_asal_kapal.id,
                isEverRun: true,
                uri: 'dm_asal_kapal/',
                isNew: false,
                dm_asal_kapals: []
              }
            } else {
              console.log('dm_asal_kapal: not exist', dm_asal_kapal)
              /* CREATE BY SAL */
              this.form.dm_asal_kapal = {
                // eslint-disable-next-line camelcase
                ...this.form.dm_asal_kapal
              }
            }

            if (!!form_tug_boat) {
              const { tug_boat, file_storages } = form_tug_boat
              const { dm_shipping_agent } = tug_boat
              this.form.form_tug_boat_id = form_tug_boat.id

              this.form.schedule.position_jetty_tb =
                form_tug_boat.position_jetty
              this.form.schedule.position_baris_jetty_tb =
                form_tug_boat.baris_position_jetty

              if (file_storages) {
                file_storages.forEach((item) => {
                  this.isHaveFile.push({
                    original_name: `${item.original_name}`,
                    size: `${item.size}`,
                    type: item.type,
                    download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                  })
                })
              }

              let keg_s_tb = form_tug_boat.kegiatan_start_time_tb
              let keg_e_tb = form_tug_boat.kegiatan_end_time_tb

              let keg_s_tb_p1 = form_tug_boat.kegiatan_start_time_tb_p1
              let keg_e_tb_p1 = form_tug_boat.kegiatan_end_time_tb_p1
              let keg_s_tb_p2 = form_tug_boat.kegiatan_start_time_tb_p2
              let keg_e_tb_p2 = form_tug_boat.kegiatan_end_time_tb_p2

              this.form.schedule.kegiatan_start_time_tb = keg_s_tb
              this.form.schedule.kegiatan_end_time_tb = keg_e_tb
              this.form_realisasi.keg_tb = this.durationAsString(
                moment(keg_s_tb),
                moment(keg_e_tb)
              )
              this.form.schedule.kegiatan_start_time_tb_p1 = keg_s_tb_p1
              this.form.schedule.kegiatan_end_time_tb_p1 = keg_e_tb_p1
              this.form_realisasi.keg_tb_p1 = this.durationAsString(
                moment(keg_s_tb_p1),
                moment(keg_e_tb_p1)
              )
              this.form.schedule.kegiatan_start_time_tb_p2 = keg_s_tb_p2
              this.form.schedule.kegiatan_end_time_tb_p2 = keg_e_tb_p2
              this.form_realisasi.keg_tb_p2 = this.durationAsString(
                moment(keg_s_tb_p2),
                moment(keg_e_tb_p2)
              )

              this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb
              this.form.schedule.no_rpkro_tb = form_tug_boat.no_rpkro_tb
              this.form.schedule.no_ppk_tb = form_tug_boat.no_ppk_tb
              this.form.schedule.disetujui_time_tb =
                form_tug_boat.disetujui_time_tb
              this.form.schedule.no_pindah_tb = form_tug_boat.no_pindah_tb

              let nowRKA2 = moment(form_tug_boat.tug_sandar_start_time)
              let endRKA2 = moment(form_tug_boat.tug_sandar_end_time) // another date
              this.form_realisasi.rka2 = this.durationAsString(nowRKA2, endRKA2)

              this.form.tug_boat = {
                ...this.form.tug_boat,
                ...tug_boat,
                tug_sandar_start_time: form_tug_boat.tug_sandar_start_time,
                tug_sandar_end_time: form_tug_boat.tug_sandar_end_time
              }
              this.form.tug_boat.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
            }
            this.form.dm_river_boat = {
              // eslint-disable-next-line camelcase
              ...dm_river_boat,
              updateID: dm_river_boat.id,
              isEverRun: true,
              uri: 'dm_river_boat/',
              isNew: false
            }

            this.form.dm_shipping_agent = {
              // eslint-disable-next-line camelcase
              ...dm_shipping_agent,
              updateID: dm_shipping_agent.id,
              isEverRun: true,
              uri: 'dm_shipping_agent/',
              isNew: false
            }
            if (file_storage_bunkers) {
              file_storage_bunkers.forEach((item) => {
                this.form.schedule.no_ijin_bunker = item.no_ijin_bunker
                this.form.schedule.total_bunker = item.total_bunker
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  type: item.type,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
                this.form_realisasi.bunker_s = item.kegiatan_start_time
                this.form_realisasi.bunker_e = item.kegiatan_end_time
                this.form_realisasi.bunker = this.durationAsString(
                  moment(item.kegiatan_start_time),
                  moment(item.kegiatan_end_time)
                )
              })
            }

            /* isNoRpkro ?? */
            const isBunker = form_tbbm.kegiatan
            const isLct = form_tbbm.jenis_kapal
            const hardcodeJenisKapal = 'LCT / KM / MT / SPOB / Sea Truck'
            const hardcodeKegiatan = 'BUNKER'
            if(isBunker === hardcodeKegiatan && isLct === hardcodeJenisKapal){
              this.isNoRpkro = true
            }



            console.log('init payload:', this.form)
          })
          .catch((err) => {
            this.isTimerDone()
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.isTimerDone()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
.invoice-preview-wrapper {
  .invoice-actions {
    position: fixed !important;
    right: 0 !important;
    @media (max-width: 768px) {
      position: inherit !important;
    }
  }
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
